.input_tiny {
  width: 50px;
}

.input_small {
  width: 100px;
}

.input_medium {
  width: 150px;
}

.input_large {
  width: 200px;
}

.input_xlarge {
  width: 250px;
}

.input_xxlarge {
  width: 300px;
}

.input_full {
  width: 100%;
}

.input_full_wrap {
  display: block;
  padding-right: 8px;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input:invalid,
button:invalid,
a.button:invalid,
select:invalid,
textarea:invalid {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input:focus,
button:focus,
a.button:focus,
select:focus,
textarea:focus {
  -webkit-box-shadow: #0066ff 0 0 5px 0;
  -moz-box-shadow: #0066ff 0 0 5px 0;
  box-shadow: #0066ff 0 0 5px 0;
  z-index: 1;
}

input[type="file"]:focus, input[type="file"]:active,
input[type="radio"]:focus,
input[type="radio"]:active,
input[type="checkbox"]:focus,
input[type="checkbox"]:active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

button,
a.button,
input[type="reset"],
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background: #dddddd url('images/button.png?1298351022') repeat-x;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #dddddd));
  background-image: -webkit-linear-gradient(#ffffff, #dddddd);
  background-image: -moz-linear-gradient(#ffffff, #dddddd);
  background-image: -o-linear-gradient(#ffffff, #dddddd);
  background-image: linear-gradient(#ffffff, #dddddd);
  border: 1px solid;
  border-color: #dddddd #bbbbbb #999999;
  cursor: pointer;
  color: #333333;
  display: inline-block;
  font: bold 12px/1.3 "Helvetica Neue", Arial, "Liberation Sans", FreeSans, sans-serif;
  outline: 0;
  overflow: visible;
  margin: 0;
  padding: 3px 10px;
  text-shadow: white 0 1px 1px;
  text-decoration: none;
  vertical-align: top;
  width: auto;
  *padding-top: 2px;
  *padding-bottom: 0;
}
button:hover,
a.button:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(1px, #eeeeee), color-stop(100%, #cccccc));
  background-image: -webkit-linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  background-image: -moz-linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  background-image: -o-linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  background-image: linear-gradient(#ffffff, #eeeeee 1px, #cccccc);
  text-decoration: none;
}
button:active,
a.button:active,
input[type="reset"]:active,
input[type="submit"]:active,
input[type="button"]:active {
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #dddddd), color-stop(100%, #eeeeee));
  background-image: -webkit-linear-gradient(#dddddd, #eeeeee);
  background-image: -moz-linear-gradient(#dddddd, #eeeeee);
  background-image: -o-linear-gradient(#dddddd, #eeeeee);
  background-image: linear-gradient(#dddddd, #eeeeee);
  -webkit-box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px 2px 0;
  -moz-box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px 2px 0;
  box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px 2px 0;
  border-color: #999999 #bbbbbb #dddddd;
}
button::-moz-focus-inner,
a.button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

a.button {
  *padding-bottom: 3px;
}

button {
  *padding-top: 1px;
  *padding-bottom: 1px;
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid;
  border-color: #848484 #c1c1c1 #e1e1e1;
  color: black;
  outline: 0;
  margin: 0;
  padding: 2px 3px;
  text-align: left;
  font-size: 13px;
  font-family: Arial, "Liberation Sans", FreeSans, sans-serif;
  height: 1.8em;
  vertical-align: top;
  *padding-top: 2px;
  *padding-bottom: 1px;
  *height: auto;
}
textarea[disabled],
select[disabled],
input[type="date"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="email"][disabled],
input[type="month"][disabled],
input[type="number"][disabled],
input[type="password"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="text"][disabled],
input[type="time"][disabled],
input[type="url"][disabled],
input[type="week"][disabled] {
  background-color: #eeeeee;
}

button[disabled],
input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled],
a.button_disabled {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  color: #888888;
  cursor: default;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #888888;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #888888;
}

input.placeholder_text,
textarea.placeholder_text {
  color: #888888;
}

textarea,
select[size],
select[multiple] {
  height: auto;
}

select[size="0"],
select[size="1"] {
  height: 1.8em;
  *height: auto;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  select[size],
  select[multiple],
  select[multiple][size] {
    background-image: none;
    padding-right: 3px;
  }

  select,
  select[size="0"],
  select[size="1"] {
    background-image: url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
  }

  ::-webkit-validation-bubble-message {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #666666), color-stop(1, black));
    border: 0;
    color: white;
    font: 13px/17px "Lucida Grande", Arial, "Liberation Sans", FreeSans, sans-serif;
    overflow: hidden;
    padding: 15px 15px 17px;
    text-shadow: black 0 0 1px;
    min-height: 16px;
  }

  ::-webkit-validation-bubble-arrow,
  ::-webkit-validation-bubble-top-outer-arrow,
  ::-webkit-validation-bubble-top-inner-arrow {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #666666;
    border-color: #666666;
  }
}
textarea {
  min-height: 40px;
  overflow: auto;
  resize: vertical;
  width: 100%;
}

optgroup {
  color: black;
  font-style: normal;
  font-weight: normal;
  font-family: Arial, "Liberation Sans", FreeSans, sans-serif;
}
optgroup::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ie6_button,
* html button,
* html a.button {
  background: #dddddd url('images/button.png?1298351022') repeat-x;
  border: 1px solid;
  border-color: #dddddd #bbbbbb #999999;
  cursor: pointer;
  color: #333333;
  font: bold 12px/1.2 Arial, sans-serif;
  padding: 2px 10px 0;
  text-decoration: none;
  overflow: visible;
  vertical-align: top;
  width: auto;
}

* html a.button {
  position: relative;
  top: 3px;
  padding-bottom: 2px;
}

* html button {
  padding-top: 1px;
  padding-bottom: 1px;
}

.ie6_input,
* html textarea,
* html select {
  background: white;
  border: 1px solid;
  border-color: #848484 #c1c1c1 #e1e1e1;
  color: black;
  padding: 2px 3px 1px;
  font-size: 13px;
  font-family: Arial, sans-serif;
  vertical-align: top;
}

* html select {
  margin-top: 1px;
}

.placeholder_text,
.ie6_input_disabled,
.ie6_button_disabled {
  color: #888888;
}

.ie6_input_disabled {
  background: #eeeeee;
}
